// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts-face 

@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_bold_italic-webfont.woff2') format('woff2'),
        url('/fonts/druk_bold_italic-webfont.woff') format('woff');
   font-weight: 700;
   font-style: italic;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_bold-webfont.woff2') format('woff2'),
        url('/fonts/druk_bold-webfont.woff') format('woff');
   font-weight: 700;
   font-style: normal;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_heavy_italic-webfont.woff2') format('woff2'),
        url('/fonts/druk_heavy_italic-webfont.woff') format('woff');
   font-weight: 900;
   font-style: italic;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_heavy-webfont.woff2') format('woff2'),
        url('/fonts/druk_heavy-webfont.woff') format('woff');
   font-weight: 900;
   font-style: normal;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_medium_italic-webfont.woff2') format('woff2'),
        url('/fonts/druk_medium_italic-webfont.woff') format('woff');
   font-weight: 500;
   font-style: italic;
}
@font-face {
   font-family: 'drukmedium';
   src: url('/fonts/druk_medium-webfont.woff2') format('woff2'),
        url('/fonts/druk_medium-webfont.woff') format('woff');
   font-weight: 500;
   font-style: normal;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_super_italic-webfont.woff2') format('woff2'),
        url('/fonts/druk_super_italic-webfont.woff') format('woff');
   font-weight: 950;
   font-style: italic;
}
@font-face {
   font-family: 'druk';
   src: url('/fonts/druk_super-webfont.woff2') format('woff2'),
        url('/fonts/druk_super-webfont.woff') format('woff');
   font-weight: 950;
   font-style: normal;
}


@font-face {
   font-family: 'aurel';
   src: url('/fonts/aurel_regular-webfont.woff2') format('woff2'),
        url('/fonts/aurel_regular-webfont.woff') format('woff');
   font-weight: 400;
   font-style: normal;
}
@font-face {
   font-family: 'aurel';
   src: url('/fonts/aurel_bold-webfont.woff2') format('woff2'),
        url('/fonts/aurel_bold-webfont.woff') format('woff');
   font-weight: 700;
   font-style: normal;
}
@font-face {
   font-family: 'aurel';
   src: url('/fonts/aurel_semi-bold-webfont.woff2') format('woff2'),
        url('/fonts/aurel_semi-bold-webfont.woff') format('woff');
   font-weight: 600;
   font-style: normal;
}
@font-face {
   font-family: 'aurel';
   src: url('/fonts/aurel_light-webfont.woff2') format('woff2'),
        url('/fonts/aurel_light-webfont.woff') format('woff');
   font-weight: 300;
   font-style: normal;
}

// Fonts
$fontTitle: 'druk', 'sans-serif';
$fontText: 'aurel', 'sans-serif';

// Accents Colors
$colorAccent1: #000033;
$colorAccent2: #EF7C19;
$light: #7D7D7D;
$white : #f5F5F5;

$colorSuccess: #56C568;
$colorError: #dd2c00;

$skewValue: 25deg;
$skewValueRevert: -25deg;
