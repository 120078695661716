.section-cloud-words {
   width: 100%;
   color: white;
   font-family: $fontTitle;
   padding-bottom: 90px;
   .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
   &.bg-blue {
      background-color: $colorAccent1;
   }
   &.bg-white {
      background-color: $white;
      .sub-title {
         color: $colorAccent1;
      }
      .cloud-words-container {
         .cloud-words-row {
            &:nth-child(odd) {
               li {
                  color: $colorAccent1;
                  &:nth-child(2n + 1) {
                     color: $colorAccent2;
                     svg {
                        path {
                           fill: $colorAccent1;
                        }
                     }
                  }
                  &:nth-child(2n) {
                     svg {
                        path {
                           fill: $colorAccent2;
                        }
                     }
                  }
               }
            }
            li {
               color: $colorAccent2;
               &:nth-child(2n + 1) {
                  color: $colorAccent1;
                  svg {
                     path {
                        fill: $colorAccent2;
                     }
                  }
               }
               &:nth-child(2n) {
                  svg {
                     path {
                        fill: $colorAccent1;
                     }
                  }
               }
            }
         }
      }
   }
   .sub-title {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      font-family: $fontText;
      margin-bottom: 40px;
      margin-top: 80px;
      @media (max-width: 700px) {
         font-size: 16px;
         line-height: 24px;
         margin-top: 40px;
      }
   }
}
.cloud-words-container {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   list-style: none;
   padding-left: 0;
   width: 950px;
   .cloud-words-row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;
      &:nth-child(odd) {
         li {
            color: white;
            &:nth-child(2n + 1) {
               color: $colorAccent2;
               svg {
                  path {
                     fill: $white;
                  }
               }
            }
            &:nth-child(2n) {
               svg {
                  path {
                     fill: $colorAccent2;
                  }
               }
               
            }
         }
      }
      li {
         color: $colorAccent2;
         &:hover {
            scale: 1.05;
         }
         a {
            position: relative;
            margin-right: 20px;
            svg {
               position: absolute;
               top: 50%;
               transform: translateY(-30%);
               margin-left: 10px;
               @media (max-width: 380px) {
                  margin-left: 6px;
               }
            }
         }
         &:nth-child(2n + 1) {
            color: white;
            svg {
               path {
                  fill: $colorAccent2;
               }
            }
         }
      }
      a[data-weight="1"] {
         font-size: 24px;
         @media (max-width: 1000px) {
            font-size: 22px;
         }
         @media (max-width: 750px) {
            font-size: 20px;
         }
         @media (max-width: 380px) {
            font-size: 18px;
         }
      }
      a[data-weight="2"] {
         font-size: 30px;
         @media (max-width: 1000px) {
            font-size: 25px;
         }
         @media (max-width: 750px) {
            font-size: 23px;
         }
         @media (max-width: 380px) {
            font-size: 21px;
         }
      }
      a[data-weight="3"] {
         font-size: 36px;
         @media (max-width: 1000px) {
            font-size: 28px;
         }
         @media (max-width: 750px) {
            font-size: 26px;
         }
         @media (max-width: 380px) {
            font-size: 24px;
         }
      }
      a[data-weight="4"] {
         font-size: 42px;
         @media (max-width: 1000px) {
            font-size: 31px;
         }
         @media (max-width: 750px) {
            font-size: 29px;
         }
         @media (max-width: 380px) {
            font-size: 27px;
         }
      }
      a[data-weight="5"] {
         font-size: 42px;
         @media (max-width: 1000px) {
            font-size: 34px;
         }
         @media (max-width: 750px) {
            font-size: 32px;
         }
         @media (max-width: 380px) {
            font-size: 30px;
         }
      }
      a[data-weight="6"] {
         font-size: 48px;
         @media (max-width: 1000px) {
            font-size: 37px;
         }
         @media (max-width: 750px) {
            font-size: 35px;
         }
         @media (max-width: 380px) {
            font-size: 33px;
         }
      }
      a[data-weight="7"] {
         font-size: 54px;
         @media (max-width: 1000px) {
            font-size: 40px;
         }
         @media (max-width: 750px) {
            font-size: 38px;
         }
         @media (max-width: 380px) {
            font-size: 36px;
         }
      }
      a[data-weight="8"] {
         font-size: 60px;
         @media (max-width: 1000px) {
            font-size: 43px;
         }
         @media (max-width: 750px) {
            font-size: 42px;
         }
         @media (max-width: 380px) {
            font-size: 40px;
         }
      }
      a[data-weight="9"] {
         font-size: 66px;
         @media (max-width: 1000px) {
            font-size: 46px;
         }
         @media (max-width: 750px) {
            font-size: 45px;
         }
         @media (max-width: 380px) {
            font-size: 43px;
         }
      }
      a[data-weight="10"] {
         font-size: 72px;
         @media (max-width: 1000px) {
            font-size: 49px;
         }
         @media (max-width: 750px) {
            font-size: 48px;
         }
         @media (max-width: 380px) {
            font-size: 46px;
         }
      }
      a {
         font-family: $fontTitle;
         display: block;
         padding: 0 20px;
         text-decoration: none;
         text-transform: uppercase;
         font-weight: 500;
         white-space: nowrap;
         @media (max-width: 380px) {
            padding: 0 10px;
         }
      }
   }
   &-mobile {
      display: none;
      @media (max-width: 700px) {
         display: block;
      }
   }
   &-desktop {
      @media (max-width: 700px) {
         display: none;
      }
   }
}