.all-services {
   .services-title {
      background-color: $colorAccent1;
      padding-top: 90px;
   }
}
.page-service {
   background-color: $colorAccent1;
   padding: 100px 0;
   @media (max-width: 850px) {
      padding: 50px 0;
   }
   .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5%;
      @media (max-width: 850px) {
         flex-direction: column;
         gap: 30px;
      }
   }
   &-title-container {
      display: flex;
      position: relative;
      align-items: flex-end;
      min-height: 200px;
      width: 50%;
      @media (max-width: 850px) {
         width: 100%;
         align-items: flex-start;
         min-height: 170px;
      }
   }
   &-number {
      position: absolute;
      top: -10px;
      left: 0px;
      font-size: 260px;
      line-height: 200px;
      font-family: $fontTitle;
      font-weight: 950;
      -webkit-text-stroke: 3px $colorAccent2;
      color: transparent;
      z-index: 0;
      letter-spacing: 10px;
      @media (max-width: 850px) {
         font-size: 220px;
         line-height: 170px;
      }
   }
   &-title {
      display: inline-block;
      color: $white;
      font-size: 42px;
      line-height: 42px;
      font-weight: 900;
      bottom: 40px;
      padding: 0 0 40px 195px;
      width: 100%;
      height: 100%;

      z-index: 1;
      @media (max-width: 850px) {
         padding: 100px 20px 0;
      }
   }
   &-text-container {
      position: relative;
      width: 50%;
      color: $white;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      height: 190px;
      overflow: hidden;
      @media (max-width: 850px) {
         width: 100%;
      }
      &.is-unroll {
         transition: all .5s;
         height: auto;
         .content-blur {
            display: none;
         }
      }
      .content-blur {
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 120px;
         background: linear-gradient(0deg, #000033 0%, rgba(0, 0, 51, 0) 100%);
         .show-more {
            position: absolute;
            bottom: 4px;
            left: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            svg {
               margin-left: 10px;
            }
            &::before {
               content: "";
               position: absolute;
               bottom: -4px;
               left: 0;
               width: 100%;
               height: 2px;
               scale: 0;
               background-color: $colorAccent2;
               transform-origin: center center ;
               transition: all .5s;
            }
            &:hover {
               &::before {
                  scale: 1;
               }
               svg {
                  transition: all .3s;
                  transform: rotate(-45deg);
               }
            }
         }
      }
   }
   &:nth-child(2n + 1) {
      background-color: $white;
      .page-service-number {
         color: $colorAccent2;
      }
      .page-service-title {
         color: $colorAccent1;
      }
      .page-service-text-container {
         color: $colorAccent1;
         .content-blur {
            background: linear-gradient(0deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
         }
      } 
   }
}