.section-culture {
   padding: 80px 0;
   color: $colorAccent1;
   @media (max-width: 1000px) {
      padding: 50px 0;
   }
   .double-title {
      margin-bottom: 80px ;
      @media (max-width: 700px) {
         margin-bottom: 0;
      }
   }
   .sub-section-container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      @media (max-width: 1000px) {
         gap: 50px;
         margin-top: 40px;
      }
      .sub-section {
         position: relative;
         &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            font-size: 42px;
            line-height: 42px;
            margin-bottom: 28px;
            @media (max-width: 700px) {
               font-size: 32px;
               line-height: 32px;
               margin-bottom: 20px;
            }
         }
         &-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            @media (max-width: 700px) {
               font-size: 14px;
               line-height: 22px;
            }
         }
         .image-background {
            position: absolute;
            top: -15px;
            right: 0;
            height: 110px;
            width: 110px;
            z-index: -1;
            opacity: .1;
            svg {
               height: 100%;
               width: 100%;
            }
         }
      }
   }
}