.scroll-text-home{
   overflow: hidden;
   display: flex;
   width: 100%;
   background-color: $colorAccent2;
   padding: 20px 0 30px;
   @media (max-width: 700px) {
      padding: 15px 0 20px;
   }
   .scroll-text {
      font-family: $fontTitle;
      white-space: nowrap;
      font-size: 110px;
      line-height: 110px;
      animation: scrollTxt linear infinite;
      text-transform: uppercase;
      color: $colorAccent1;
      @media (max-width: 700px) {
         font-size: 32px;
         line-height: 32px;
      }
      &-stroke {
         -webkit-text-stroke: 2px $colorAccent1;
         color: transparent;
         @media (max-width: 700px) {
            -webkit-text-stroke: 1px $colorAccent1;
         }
      }
   }
}

@keyframes scrollTxt {
   0%{
     transform: translate(0%, 0);
   }
   100% {
     transform: translate(-100%, 0);
   }
 }