.header {
   position: fixed;
   top: 0px;
   left: 0;
   width: 100%;
   height: 90px;
   z-index: 90;
   background: rgba(0, 0, 51, 0);
   transition: .5s linear;
   .wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
   }
   .contact-menu {
      display: flex;
      flex-direction: row;
      margin-right: 120px;
      .btn-classic {
         line-height: 26px;
         padding: 9px 30px;
         @media (max-width: 1000px) {
            display: none;
         }
      }
   }
   &.header-normal {
      background: rgba(0, 0, 51, 0.8);
      backdrop-filter: blur(4px);
      .header-logo {
         display: flex;
         align-items: center;
         width: 194px;
         height: 90px;
         img {
            width: 100%;
         }
         @media (max-width: 700px) {
            width: 161px;
         }
         @media (max-width: 500px) {
            width: 128px;
         }
      }
   }
   &.header-home {
      .btn-classic {
         transform: translateY(-90px);
         transition: .5s linear;
      }
      .header-logo {
         transform: translateY(-90px);
         transition: .5s linear;
         display: flex;
         align-items: center;
         width: 194px;
         height: 90px;
         img {
            width: 100%;
         }
         @media (max-width: 700px) {
            height: 90px;
            width: 161px;
         }
         @media (max-width: 500px) {
            width: 128px;
         }
      }
      &.is-visible {
         background: rgba(0, 0, 51, 0.8);
         backdrop-filter: blur(4px);
         transition: .5s linear;
         .btn-classic {
            transition: .5s linear;
            transform: translateY(0);
         }
         .header-logo {
            transition: .5s linear;
            transform: translateY(0);
         }
      }
   }
}
.hamburger-menu {
   position: absolute;
   top: 25px;
   right: 40px;
   height: 40px;
   width: 40px;
   cursor: pointer;
   z-index: 10;
   &::before {
      content: "";
      position: absolute;
      top: 14px;
      right: 0;
      width: 40px;
      height: 3px;
      background-color: white;
   }
   &::after {
      content: "";
      position: absolute;
      top: 26px;
      right: 0;
      width: 25px;
      height: 3px;
      background-color: white;
   }
   &.is-visible {
      &::before {
         top: 50%;
         width: 25px;
         transform: rotate(45deg);
         border-radius: 1px;
         animation: top-bar 2s backwards ;
      }
      &::after {
         top: 50%;
         transform: rotate(-45deg);
         animation: bottom-bar 2s backwards ;
         border-radius: 1px;
      }
   }
   @media (max-width: 500px) {
      right: 16px;
   }
}

@keyframes top-bar {
   0% {
      top: 14px;
      width: 40px;
      transform: rotate(0);
   }
   25% {
      top: 50%;
      width: 25px;
      transform: rotate(0);
   }
   100% {
      top: 50%;
      transform: rotate(45deg);
   }
}
@keyframes bottom-bar {
   0% {
      top: 26px;
      width: 25px;
      transform: rotate(0);
   }
   25% {
      top: 50%;
      width: 25px;
      transform: rotate(0);
   }
   100% {
      top: 50%;
      transform: rotate(-45deg);
   }
}