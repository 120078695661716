.btn {
   height: 46px;
   border-radius: 23px;

   &-classic {
      background-color: $colorAccent2;
      font-size: 16px;
      line-height: 46px;
      font-weight: 700;
      padding: 13px 30px;
      color: $white;
      border: 1px solid $colorAccent2;
      @media (max-width: 700px) {
         display: flex;
         justify-content: center;
         align-items: center;
      }
      svg {
         transition: all .3s;
      }
   }
   svg {
      margin-left: 10px;
   }
   &:hover {
      transition: all 1s;
      background: transparent;
      svg {
         transition: all .3s;
         transform: rotate(45deg);
         path {
            fill: $colorAccent2;
         }
      }
   }
}