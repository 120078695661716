// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
}

body {
  font-family: $fontText;
  font-weight: 400;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #4F4F4F;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #4F4F4F;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #4F4F4F;
}
:-moz-placeholder { /* Firefox 18- */
  color: #4F4F4F;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.responsive-img {
  width: 100%;
  height: auto;
}

.flickity-prev-next-button {
  background: none;
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
}

::-moz-selection { background: $colorAccent1; color: #fff; }
::selection { background: $colorAccent1; color: #fff; }

[v-cloak] {
  display: none;
}

.wrapper {
  max-width: 1190px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  @media (max-width: 500px) {
    padding: 0 16px;
 }
}