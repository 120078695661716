.social-media {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 10px;
   a {
      display: flex;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
      background-color: $colorAccent2;
      border-radius: 50%;
   }
}