.navigation {
   position: absolute;
   height: 100vh;
   width: 100vw;
   overflow-y: scroll;
   top: 0;
   left: 0;
   transform: translateX(-150%);
   padding-bottom: 50px;
   background-color: $colorAccent1;
   transition: 1s;
   @media (max-width: 700px) {
      padding-bottom: 30px;
   }
   .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: flex-start;
   }
   .btn.btn-classic {
      width: 100%;
      transform: translateY(0px);
      display: none;
      @media (max-width: 700px) {
         display: flex;
      }
   }
   .navigation-logo {
      display: flex;
      align-items: center;
      width: 194px;
      height: 90px;
      @media (max-width: 700px) {
         height: 90px;
         width: 161px;
      }
      @media (max-width: 500px) {
         width: 128px;
      }
      img {
         width: 100%;
      }
   }
   .nav-link-container {
      display: flex;
      flex-direction: column;
      color: transparent;
      font-size: 160px;
      line-height: 140px;
      gap: 30px;
      @media (max-width: 700px) {
         font-size: 130px;
         line-height: 120px;
      }
      @media (max-width: 500px) {
         font-size: 100px;
         line-height: 90px;
      }
      a {
         font-family: druk;
         text-transform: uppercase;
         -webkit-text-stroke: 1px white;
         width: max-content;
         &:hover {
            color: white;
         }
      }
   }
   &.is-visible {
      transition: 1s;
      transform: translateX(0);
   }
}

.lang-social-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-end;
   color: #F5F5F5;
   gap: 30px;
   font-weight: 700;
   margin-left: auto;
   .lang-switcher-container {
      position: relative;
      svg {
         margin-left: 10px;
      }
      &::before {
         content: "";
         position: absolute;
         bottom: -4px;
         left: 0;
         width: 100%;
         height: 2px;
         scale: 0;
         background-color: $colorAccent2;
         transform-origin: center center ;
         transition: all .5s;
      }
      &:hover {
         &::before {
            scale: 1;
         }
         svg {
            transition: all .3s;
            transform: rotate(45deg);
         }
      }
   }
   @media (max-width: 700px) {
      margin: 0;
   }
}