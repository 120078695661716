.who-are-we {
   background-color: $colorAccent1;
   width: 100%;
   overflow: hidden;
   &-content {
      padding-top: 163px;
   }
   .text-sub-title {
      margin: 70px 0;
      color: $white;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      @media (max-width: 1000px) {
         margin: 30px 0;
      }
      @media (max-width: 700px) {
         width: 100%;
         font-size: 16px;
         line-height: 24px;
      }
   }
   .logo-text {
      margin-bottom: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 95px;
      @media (max-width: 700px) {
         flex-direction: column;
         margin-bottom: 40px;
      }
      .logo-container {
         width: 50%;
         @media (max-width: 700px) {
            width: 100%;
         }
         img {
            width: 100%;
         }
      }
      .text-container {
         width: 50%;
         color: $white;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         @media (max-width: 700px) {
            width: 100%;
            font-size: 14px;
         }
      }
   }
   .image-bottom-container {
      display: flex;
      position: relative;
      justify-content: center;
      overflow: hidden;
      height: 570px;
      top: -56px;
      @media (max-width: 1000px) {
         height: 465px;
      }
      @media (max-width: 700px) {
         top: -40px;
         height: 360px;
      }
      img {
         position: absolute;
         z-index: 10;height: 100%;
      }
   }
}