.team-member-popup {
   display: none;
   position: fixed;
   justify-content: center;
   align-items: center;
   inset: 0;
   height: 100vh;
   width: 100%;
   z-index: 100;
   background: rgba(0, 0, 51, 0.80);
   cursor: auto;
   @media (max-width: 700px) {
      overflow: scroll;
      align-items: flex-start;
      &::-webkit-scrollbar {
         display: none;
      }
   }
   .cross-popup {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
      z-index: 10;
      @media (max-width: 700px) {
         top: 20px;
         right: 10px;
      }
   }
   &.is-visible {
      display: flex;
   }
}

.member {
   position: relative;
   width: calc(100% - 170px);
   max-width: 750px;
   height: 100%;
   max-height: 770px;
   background-color: $white;
   margin-left: 175px;
   padding: 80px 90px 80px 270px ;
   color: $colorAccent1;
   @media (max-width: 1000px) {
      margin: auto 20px auto 195px;
      padding: 80px 60px 80px 210px ;
   }
   @media (max-width: 700px) {
      margin: 126px 16px 16px;
      padding: 250px 20px 40px;
      height: auto;
      max-height: none;
      width: calc(100% - 32px);
   }
   &-image-wrapper{
      position: absolute;
      width: 350px;
      height: 440px;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      background-color: $colorAccent2;
      @media (max-width: 700px) {
         left: 50%;
         top: 60px;
         width: 265px;
         height: 340px;
      }
      @media (max-width: 380px) {
         width: 220px;
         height: 300px;
      }
   }
   &-name {
      font-weight: 900;
      font-size: 50px;
      line-height: 50px;
      text-transform: uppercase;
   }
   &-job {
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
   }
   &-contact-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin: 20px 0 30px;
      a {
         display: flex;
         height: 40px;
         width: 40px;
         justify-content: center;
         align-items: center;
         border-radius: 50%;
         background-color: $colorAccent2;
      }
   }
   &-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      overflow-y: scroll;
      height: calc(100% - 175px);
   }
}

.no-scroll {
   inset: 0;
   overflow: hidden;
}