.hero-home-container {
   position: relative;
   height: 100vh;
   width: 100%;
   overflow: hidden;
   img {
      height: 100%;
      width: 100%;
      object-fit: cover;
   }
   .hero-content {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      max-width: 580px;
      width: 100%;
      lottie-player {
         margin: 0 30px;
         width: calc(100% - 60px);
      }
      .big-title {
         position: absolute;
         top: calc(100% + 50px);
         left: 50%;
         font-family: $fontTitle;
         transform: translateX(-50%);
         max-width: 100%;
         width: 100%;
         font-size: 42px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 1px;
         text-align: center;
         color: white;
         @media (max-width: 500px) {
            font-size: 32px;
            line-height: 32px;
            top: calc(100% + 20px);
         }
      }
   }
}