.team {
   padding: 100px 0;
   background-color: $colorAccent1;
   @media (max-width: 1000px) {
      flex-direction: column-reverse;
      padding: 50px 0px;
   }
   .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 90px;
      @media (max-width: 1000px) {
         flex-direction: column-reverse;
         gap: 40px;
      }
   }
   &-images {
      display: flex;
      flex-direction: column;
      height: auto;
      min-width: 350px;
      width: 350px;
      gap: 30px;
      flex-wrap: wrap;
      @media (max-width: 1000px) {
         flex-direction: row;
         gap: 25px;
         min-width: 335px;
         width: 555px;
      }
      @media (max-width: 700px) {
         flex-direction: column;
         gap: 15px;
         width: 335px;
      }
      @media (max-width: 375px) {
         width: 295px;
         min-width: 295px;
      }
      &.team-3 {
         max-height: 430px;
         .member-image-container:nth-child(3) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
      &.team-4 {
         max-height: 530px;
         .member-image-container:nth-child(3) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
      &.team-5 {
         max-height: 660px;
         .member-image-container:nth-child(4) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
      &.team-6 {
         max-height: 760px;
         .member-image-container:nth-child(4) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
      &.team-7 {
         max-height: 890px;
         .member-image-container:nth-child(5) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
      &.team-8 {
         max-height: 990px;
         .member-image-container:nth-child(5) {
            margin-top: 100px;
            @media (max-width: 1000px) {
               margin-top: 0;
            }
            @media (max-width: 700px) {
               margin-top: 100px;
            }
         }
      }
   }
}
.team-content {
   display: flex;
   flex-direction: column;
   gap: 60px;
   .team-description {
      color: $white;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 700px) {
         font-size: 14px;
         line-height: 22px;
      }
   }
}

.member-image-container {
   position: relative;
   height: 200px;
   width: 160px;
   overflow: hidden;
   background-color: $white;
   cursor: pointer;
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
   }
   .name-job {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 50px;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      padding: 0 40px 0 10px;
      background: rgba(245, 245, 245, 0.2);
      backdrop-filter: blur(4px);
      color: $white;
      .name {
         font-weight: 700;
         font-size: 12px;
         line-height: 12px;
         font-family: $fontText;
      }
      .job {
         font-weight: 400;
         font-size: 10px;
         line-height: 11px;
      }
      .arrow {
         position: absolute;
         right: 15px;
         bottom: 16px;
      }
   }
   @media (max-width: 375px) {
      height: 190px;
      width: 140px;
   }
}