.services-home {
   padding: 80px 0 100px;
   width: 100vw;
   overflow: hidden;
   @media (max-width: 700px) {
      padding: 50px 0;
   }
   .intro-scroll-services{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 60px;
      @media (max-width: 700px) {
         flex-direction: column;
         margin-top: 30px;
      }
      .intro-services{
         min-width: 285px;
         width: 50%;
         font-size: 20px;
         line-height: 32px;
         font-weight: 600;
         margin-right: 90px;
         @media (max-width: 700px) {
            width: 100%;
            min-width: none;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
         }
      }
   }
}
.scroll-services {
   width: 100%;
   overflow: visible;
   padding-bottom: 100px;
   .flickity-viewport {
      width: 100%;
      overflow: visible;
   }
   .flickity-prev-next-button {
      height: 60px;
      width: 60px;
      transform: translateY(0);
      border: 2px solid $colorAccent2;
      top: auto;
      bottom: 0;
      &.previous {
         left: auto;
         right: 75px;
         @media (max-width: 700px) {
            right: 60px;
         }
      }
      &.next {
         left: auto;
         right: 0px;
      }
      svg {
         display: block;
         scale: .5;
      }
      @media (max-width: 700px) {
         height: 50px;
         width: 50px;
      }
   }
}

.service-container {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   gap: 20px;
   height: 254px;
   width: 435px;
   margin-right: 50px;
   padding: 0 0 0 160px;
   color: $colorAccent1;
   @media (max-width: 700px) {
      width: 256px;
      padding: 0;
      height: 320px;
      margin-right: 40px;
   }
   .service-number {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 210px;
      line-height: 200px;
      font-weight: 950;
      font-family: $fontTitle;
      z-index: -1;
      letter-spacing: 10px;
      color: $colorAccent2;
      @media (max-width: 700px) {
         font-size: 200px;
         line-height: 190px;
      }
   }
   .service-title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 900;
   }
   .service-description {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
   }
   .service-link {
      position: relative;
      width: fit-content;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      svg {
         margin-left: 10px;
      }
      &::before {
         content: "";
         position: absolute;
         bottom: -4px;
         left: 0;
         width: 100%;
         height: 2px;
         scale: 0;
         background-color: $colorAccent2;
         transform-origin: center center ;
         transition: all .5s;
      }
      &:hover {
         &::before {
            scale: 1;
         }
         svg {
            transition: all .3s;
            transform: rotate(45deg);
         }
      }
   }
   &:nth-child(even) {
      .service-number {
         color: transparent;
         -webkit-text-stroke: 3px $colorAccent2;
         opacity: .8;
      }
   }
}