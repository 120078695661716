.sub-menu {
   position: fixed;
   top: 90px;
   left: 0;
   width: 100%;
   height: 36px;
   color: $white;
   z-index: 80;
   background: rgba(0, 0, 51, 0.8);
   backdrop-filter: blur(4px);
   .wrapper {
      height: 100%;
   }
   ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0;
      gap: 30px;
      flex-wrap: nowrap;
      list-style-type: none;
      height: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
         display: none;
      }
      .sub-nav-link {
         font-weight: 700;
         font-size: 16px;
         line-height: 20px;
         height: 100%;
         a {
            position: relative;
            display: inline-block;
            height: 100%;
            white-space: nowrap;
            opacity: .5;
            transition: all .5s;
            &::before {
               content: "";
               position: absolute;
               bottom: 0;
               left: 0;
               width: 100%;
               height: 4px;
               scale: 0;
               background-color: $colorAccent2;
               transform-origin: center center ;
               transition: all .5s;
            }
            &:hover {
               opacity: 1;
               &::before {
                  scale: 1;
               }
            }
         }
         &.is-active {
            a {
               opacity: 1;
               &::before {
                  scale: 1;
               }
            }
         }
      }
   }
}