// --------------------------------------------------
// Title
// --------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  font-family: $fontTitle;
}


h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

b,
strong {
  font-weight: 500;
}

p, a {
  font-family: $fontText;
}

.big-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 104%;
  letter-spacing: -0.02em;
  
  @media only screen and (max-width: 767px) {
    font-size: 55px;
  }

  // strong {
  //   font-weight: 700;
  // }
}

.fonts-loaded .big-title {
  font-family: $fontTitle;
}

.medium-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;

  strong {
    font-weight: 700;
  }
}

.fonts-loaded .medium-title {
  font-family: $fontTitle;
}

.small-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.015em;

  strong {
    font-weight: 700;
  }

  // @media screen and (min-width: 1440px) {
  //   font-size: 18px;
  //   line-height: 24px;
  // }
}

.fonts-loaded .small-title {
  font-family: $fontTitle;
}

// --------------------------------------------------
// text-emphasis: dot;
// --------------------------------------------------

.regular-text {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;

  strong {
    font-weight: 600;
    color: #3F4040;
  }

  a {
    color: $colorAccent2;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent1;
    }
  }

  &.is-centered {
    width: 100%;
    text-align: center;
    letter-spacing: 0.035em;
    font-size: 18px;
  }
}

.links {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 14px;
  color: $colorAccent2;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.button-text {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 11px;

  @media screen and (min-width: 1440px) {
    font-size: 13px;
    line-height: 13px;
  }
}

.pagination {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $colorAccent1;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: $white;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.separator-content-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 989px) {
    max-width: 700px;
    margin: 0 auto;
  }
}

ul {
  ul {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 2;
    }
  }
}

.before-line-effect {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: -25px;
    width: 6px;
    background: $colorAccent1;
    z-index: 1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.is-small {
    &:before {
      width: 4px;
      top: 0;
      bottom: 0;
      left: -30px;
    }
  }
}

.bottom-line-seperator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 80px;
    height: 4px;
    background: $colorAccent1;
    z-index: 1;
  }

  &.is-small {
    &:before {
      width: 40px;
      height: 2px;
      bottom: -15px;
    }
  }

  &.line-is-centered {
    &:before {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.double-title {
  position: relative;
  width: 100%;
  height: 160px;

  @media (max-width: 1000px) {
    height: 116px;
  }

  @media (max-width: 700px) {
   height: 74px;      
  }

  &.bg-blue {
    h2 {
      color: $white;
    }

    h6 {
      -webkit-text-stroke: 2px #F5F5F5;
    }
  }

  &.bg-white {
    h2 {
      color: $colorAccent1;
    }

    h6 {
      -webkit-text-stroke: 2px $colorAccent1;
    }
  }

  h6 {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 160px;
    font-weight: 700;
    line-height: 160px;
    text-transform: uppercase;
    color: transparent;
    opacity: 0.15;
    white-space: nowrap;

    @media (max-width: 1000px) {
      font-size: 116px;
      line-height: 116px;
    }

    @media (max-width: 700px) {
         font-size: 74px;
         line-height: 74px;
    }
  }

  h2 {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 74px;
    font-weight: 900;
    line-height: 74px;
    text-transform: uppercase;
    white-space: nowrap;

    @media (max-width: 1000px) {
      font-size: 58px;
      line-height: 58px;
      top: 53px;
    }
    
    @media (max-width: 700px) {
      font-size: 42px;
      line-height: 42px;
      top: 30px;
    }
  }
}
