.about-home {
   position: relative;
   width: 100%;
   padding: 100px 0;
   background-color: $colorAccent1;
   @media (max-width: 700px) {
      padding: 50px 16px;
      flex-direction: column;
   }
   .wrapper {
      display: flex;
      flex-direction: row;
      height: 100%;
   }
   .about-home-content {
      width: 50%;
      max-width: 570px;
      @media (max-width: 1000px) {
         max-width: 495px;
      }
      @media (max-width: 800px) {
         align-items: center;
         padding: 0;
         width: 100%;
         min-width: calc(100% - 32px);
         
      }
      .description {
         font-size: 20px;
         font-weight: 600;
         line-height: 32px;
         color: $white;
         margin: 50px 0 40px;
         max-width: 445px;
         @media (max-width: 800px) {
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            margin-top: 30px 0 0;
            max-width: none;
         }
      }
   }
   .about-home-image {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: calc(100% - 200px);
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      width: calc((100vw - 520px) / 1.1);
      @media (max-width: 1000px) {
         width: calc((100vw - 495px) / 1.1);
      }
      @media (max-width: 800px) {
         display: none;
      }
      img {
         max-width: 100%;
         max-height: 100%;
      }
   }
   .about-home-mobile-image {
      display: none;
      @media (max-width: 800px) {
         display: block;
         width: 100%;
         margin-bottom: 50px;
      }
      img {
         width: 100%;
         max-width: none;
      }
   }
}