.scroll-question-hero {
   position: absolute;
   display: flex;
   width: 100%;
   top: 15%;
   transform: translateY(-50%);
   left: 0;
   overflow: hidden;
   font-family: $fontTitle;
   font-size: 50px;
   font-weight: 900;
   line-height: 50px;
   text-transform: uppercase;
   @media (max-width: 500px) {
      font-size: 42px;
      line-height: 48px;
   }
   .scroll-text {
      white-space: nowrap;
      animation: scrollTxt linear infinite;
      &-classic {
         color: #F5F5F5;
      }
      &-stroke {
         color: transparent;
         -webkit-text-stroke: 1px #F5F5F5;
      }
      svg {
         margin-bottom: 10px;
         @media (max-width: 500px) {
            height: 10px;
            width: 10px;
            margin-bottom: 10px;
         }
      }
   }
}

@keyframes scrollTxt {
   0%{
     transform: translate(0%, 0);
   }
   100% {
     transform: translate(-100%, 0);
   }
 }