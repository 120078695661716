.footer {
   display: flex;
   flex-direction: column;
   padding: 40px 0 60px;
   background-color: $colorAccent1;
   @media (max-width: 1000px) {
      padding: 40px 0 60px;
   }
   @media (max-width: 700px) {
      padding: 50px 0;
   }
   .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
      @media (max-width: 700px) {
         flex-direction: column;
         gap: 40px;
      }
      .logo-container {
         width: 195px;
         img {
            width: 100%;
         }
      }
      .social-media {
         justify-content: flex-end;
         width: 195px;
         @media (max-width: 700px) {
            width: auto;
         }
      }
   }
   .second-row {
      text-align: center;
      p {
         font-size: 12px;
         line-height: 20px;
         font-weight: 400;
         color: #F5F5F5;
      }
   }
}
.navbar-footer {
   color: $white;
   display: flex;
   font-weight: 700;
   font-size: 14px;
   gap: 10px;
   flex-wrap: nowrap;
   padding: 0;
   margin: 0;
   list-style-type: none;
   li a {
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      padding: 5px ;
      &:hover {
         color: $colorAccent2;
      }
   }
}
